var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"121e018205951da72166ce5ed845a6bb82a5ee6d"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import packageDoJson from './package.json'

const SENTRY_SAMPLE_RATE = !!process.env.SENTRY_SAMPLE_RATE
  ? Math.round(parseFloat(process.env.SENTRY_SAMPLE_RATE) * 10) / 10
  : 0.0
const SENTRY_DEBUG = process.env.SENTRY_DEBUG === 'TRUE' ? true : false

Sentry.init({
  release: packageDoJson.version,
  dsn: process.env.HIRO_WEB__SENTRY_DSN,
  sampleRate: SENTRY_SAMPLE_RATE,
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  debug: SENTRY_DEBUG,
  replaysOnErrorSampleRate: SENTRY_SAMPLE_RATE,
  replaysSessionSampleRate: SENTRY_SAMPLE_RATE,
  integrations: [
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
})
